import axios from 'axios';

let _editorSDK;
let _token;

export const editorReady = async (editorSDK, token, {firstInstall, initialAppData}) => {
  _editorSDK = editorSDK;
  _token = token;

  if (firstInstall) {

    const appData = await editorSDK.document.tpa.app.getDataByAppDefId(token, 'bb30e0c2-fddc-4f5e-b27c-cb6078514ae3');
    const instanceId = appData.instanceId;
    debugger
    const response = await axios.get(`https://editor.wix.com/hubspot-integration/forms?instanceId=${instanceId}`);
    debugger
    const forms = response.data;
      // const forms = [
      //     {
      //         "name": "Subscribe to Default HubSpot Blog Notifications 11560675668",
      //         "guid": "4a5f6120-e682-4371-becf-a99549111985"
      //     },
      //     {
      //         "name": "New registration form (August 25, 2019 1:30:44 PM) ",
      //         "guid": "622a0b47-453e-45c1-9c8d-30ae13edffc7"
      //     },
      //     {
      //         "name": "hadar",
      //         "guid": "9a623f11-7102-4a0b-b42e-52d0d0ff8405"
      //     },
      //     {
      //         "name": "New contact us form (August 22, 2019 5:47:52 PM) ",
      //         "guid": "f4496bd9-a750-434a-8324-ecbf4bba2cc7"
      //     },
      //     {
      //         "name": "test",
      //         "guid": "fbbab05a-50c3-45ee-99a0-0cb07b8d4333"
      //     }
      // ];
    const applicationId = await editorSDK.info.getAppDefinitionId(token);
    const pageRef = await editorSDK.pages.getCurrent(token);
      const options = {
          title: 'Select the form you want to add',
          url: './settings.html',
          initialData: {
              forms: forms
          }
      };
      const returnedValue = await _editorSDK.editor.openModalPanel(_token, options);
  }
};

export const onEvent = async ({eventType, eventPayload}) => {
  const {controllerRef} = eventPayload;
  if (eventType === 'controllerGfppClicked') {
    const options = {
      title: 'Manage My First Application',
      url: './settings.html',
      initialData: {
        controllerRef
      },
      width: '80%',
      height: '80%'
    };
    await _editorSDK.editor.openComponentPanel(_token, {
      ...options,
      componentRef: controllerRef
    });
  }
};

export const getAppManifest = () => {
  return {
    controllersStageData: {
      myFirstApp: {
        default: {
          gfpp: {
            mainAction1: {
              actionId: 'manage',
              label: 'Manage My First App'
            }
          }
        }
      }
    },
    exports: {
      myFirstApp: {
        tagname: 'myFirstApp',
        widgetDisplayName: '',
        eventHandlers: {},
        synthetic: false,
        inherits: {},
        members: {
          randomize: {
            description: 'Randomize image sources',
            kind: 'function'
          }
        }
      }
    }
  };
};

